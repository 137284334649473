import { noop } from 'lodash';
import React, { FC } from 'react';

import { SeparatedContainer } from '@hofy/common';
import { Currency } from '@hofy/global';
import { ProductImage } from '@hofy/product';
import {
    ArrayField,
    Box,
    Button,
    FormFieldRecord,
    FormGridRow,
    FormNumberInput,
    FormPriceInput,
    FormSection,
    IconButton,
    LabeledInput,
    Placeholder,
    SvgIcon,
    SvgIllustration,
} from '@hofy/ui';

import { FormVariantSelect } from '../../../../components/domain/products/FormVariantSelect';
import {
    BYODOrderFormItemData,
    emptyBYODOrderItemFormData,
} from '../../../../store/byodOrders/types/BYODOrderFormData';
import { useVariantDetailsMap, VariantDetailsMap } from '../hooks/useVariantDetailsMap';

interface ItemsFormProps {
    items: ArrayField<BYODOrderFormItemData>;
    currency: Currency;
}

export const BYODItemsForm: FC<ItemsFormProps> = ({ items, currency }) => {
    const { variantDetailsMap, isLoading } = useVariantDetailsMap();

    if (isLoading) {
        return (
            <Placeholder
                illustration={SvgIllustration.List}
                title='Fetching products'
                message='Please wait'
            />
        );
    }

    return (
        <FormSection label='Products' column>
            {items.fields.length && (
                <SeparatedContainer lined orientation='vertical' spacing={20}>
                    {items.fields.map(field => (
                        <ProductRowForm
                            key={field.key}
                            item={field.api}
                            variantsById={variantDetailsMap}
                            onDeleteItem={() => items.remove(field.key)}
                            canBeDeleted={items.fields.length > 1}
                        />
                    ))}
                </SeparatedContainer>
            )}
            <Button
                label='Add new item'
                type='secondary'
                leftIcon={SvgIcon.Add}
                onClick={() => items.add(emptyBYODOrderItemFormData(currency))}
            />
        </FormSection>
    );
};

interface ProductRowFormProps {
    item: FormFieldRecord<BYODOrderFormItemData>;
    variantsById: VariantDetailsMap;
    canBeDeleted: boolean;
    onDeleteItem(): void;
}

const ProductRowForm: FC<ProductRowFormProps> = ({ item, variantsById, canBeDeleted, onDeleteItem }) => {
    return (
        <Box gap={20} row flex='auto'>
            <ProductImage
                image={item.variantId.value ? variantsById[item.variantId.value].imageUrl : null}
                size={172}
            />
            <Box flex={1}>
                <FormGridRow columns={2}>
                    <FormVariantSelect
                        label='Variant'
                        api={item.variantId}
                        placeholder='Select a variant'
                        isRequired
                        nullable
                    />
                    <LabeledInput
                        value={item.variantId.value ? variantsById[item.variantId.value].productName : null}
                        label='Product'
                        disabled
                        nullable
                        onChange={noop}
                    />

                    <FormNumberInput api={item.expectedQuantity} label='Quantity' />
                    <FormPriceInput api={item.unitPurchasePrice} label='Unit purchase price' />
                </FormGridRow>
            </Box>

            <IconButton icon={SvgIcon.Trash} onClick={onDeleteItem} disabled={!canBeDeleted} />
        </Box>
    );
};
