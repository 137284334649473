import React, { FC } from 'react';

import { BYODOrderItemDto } from '@hofy/api-admin';
import { formatVariant, useTrProductCategory } from '@hofy/api-shared';
import { formatPriceWithCurrency } from '@hofy/global';
import { Color } from '@hofy/theme';
import { BaseTable, BaseTableColumnConfig, BaseTableHeader, Icon, Paragraph3, SvgIcon } from '@hofy/ui';

import { ProductItem } from '../../../../components/domain/products/ProductItem';

interface BYODOrderItemsTableProps {
    byodItems: BYODOrderItemDto[];
}

export const BYODOrderItemsTable: FC<BYODOrderItemsTableProps> = ({ byodItems }) => {
    const trProductCategory = useTrProductCategory();

    return (
        <BaseTable
            data={byodItems}
            toKey={item => item.id}
            headerHorizontalPadding={40}
            rowHorizontalPadding={30}
            headerRenderer={BYODOrderItemsTableHeader}
            columns={[
                {
                    id: 'product',
                    header: 'Product',
                    width: 400,
                    renderer: byodOrder => (
                        <ProductItem
                            image={byodOrder.variant.image}
                            label={byodOrder.product.name}
                            subLabel={formatVariant(byodOrder.variant)}
                        />
                    ),
                },
                {
                    id: 'category',
                    header: 'Category',
                    renderer: item => trProductCategory(item.product.category),
                },
                {
                    id: 'sku',
                    header: 'SKU',
                    renderer: item => item.variant.sku,
                },
                {
                    id: 'value',
                    header: 'Value',
                    renderer: item => formatPriceWithCurrency(item.unitPurchasePrice),
                },
                {
                    id: 'expected',
                    header: 'Expected Items',
                    renderer: item => item.expectedQuantity,
                },
                {
                    id: 'received',
                    header: 'Received Items',
                    renderer: item => (
                        <Paragraph3 gap={8}>
                            {getReceivedItemsIcon(item.receivedQuantity, item.expectedQuantity)}
                            {item.receivedQuantity}
                        </Paragraph3>
                    ),
                },
            ]}
        />
    );
};

const BYODOrderItemsTableHeader = (columns: BaseTableColumnConfig<any>[]) => (
    <BaseTableHeader paddingHorizontal={40} columns={columns} bgColor={Color.BackgroundSurfaceNeutral} />
);

const getReceivedItemsIcon = (receivedQuantity: number, expectedQuantity: number) => {
    if (receivedQuantity === expectedQuantity) {
        return <Icon svg={SvgIcon.CheckCircle} color={Color.ContentPositive} />;
    }

    if (receivedQuantity < expectedQuantity) {
        return <Icon svg={SvgIcon.ArrowDown} color={Color.ContentNegative} />;
    }

    return null;
};
