import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

import {
    getEmptyInvoiceEntryAddPayload,
    getMainSubContract,
    invoiceEntriesQueryKey,
    invoiceEntryService,
    useBillingEntityQuery,
    useContractDetailsQuery,
} from '@hofy/api-admin';
import { ContractType } from '@hofy/api-shared';
import { useToast } from '@hofy/ui';

import { useInvoiceEntryForm } from './useInvoiceEntryForm';

export interface SubContractRef {
    subContractId: number;
    contractType: ContractType;
    hofySubsidiaryId: number;
    billingEntityId: number;
}

export const useCreateInvoiceEntry = (contractId: number | null, onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();
    const { contract } = useContractDetailsQuery(contractId);
    const mainRentalContract = getMainSubContract(contract ?? null);

    const mutation = useMutation({
        mutationFn: invoiceEntryService.createInvoiceEntry,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [invoiceEntriesQueryKey] });
            showToast({
                type: 'positive',
                message: 'Invoice entry created',
            });
            onSuccess?.();
        },
    });
    const form = useInvoiceEntryForm(
        {
            ...getEmptyInvoiceEntryAddPayload(),
            contractId: contractId,
        },
        mutation.mutate,
    );

    const { billingEntity } = useBillingEntityQuery(form.form.values.billingEntityId ?? undefined);

    const availableSubContracts = useMemo(() => {
        if (!contract) {
            return [];
        }
        if (form.form.values.contractType) {
            return [];
        }
        const availableSubContracts: SubContractRef[] = [];
        if (mainRentalContract) {
            availableSubContracts.push({
                contractType: ContractType.Rental,
                subContractId: mainRentalContract.idDeprecated,
                billingEntityId: mainRentalContract.billingEntity.id,
                hofySubsidiaryId: mainRentalContract.hofySubsidiary.id,
            });
        }
        if (contract.purchaseContract) {
            availableSubContracts.push({
                contractType: ContractType.Purchase,
                subContractId: contract.purchaseContract.idDeprecated,
                billingEntityId: contract.purchaseContract.billingEntity.id,
                hofySubsidiaryId: contract.purchaseContract.hofySubsidiary.id,
            });
        }
        if (contract.managementContracts.length > 0) {
            availableSubContracts.push(
                ...contract.managementContracts.map(managementContract => {
                    const res: SubContractRef = {
                        contractType: ContractType.Management,
                        subContractId: managementContract.idDeprecated,
                        billingEntityId: managementContract.billingEntity.id,
                        hofySubsidiaryId: managementContract.hofySubsidiary.id,
                    };
                    return res;
                }),
            );
        }
        return availableSubContracts;
    }, [contract]);

    useEffect(() => {
        if (!contract || availableSubContracts.length === 0) {
            return;
        }
        form.form.setValues({
            contractType: availableSubContracts[0].contractType,
            billingEntityId: availableSubContracts[0].billingEntityId,
            hofySubsidiaryId: availableSubContracts[0].hofySubsidiaryId,
        });
    }, [contract, availableSubContracts]);

    useEffect(() => {
        if (billingEntity) {
            form.changeBillingEntity(billingEntity);
        }
    }, [billingEntity]);

    return {
        form,
        isLoadingMutation: mutation.isPending,
        isError: mutation.isError,
        availableSubContracts,
    };
};
