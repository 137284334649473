import { noop } from 'lodash';
import React, { FC } from 'react';

import { useBYODOrderQuery } from '@hofy/api-admin';
import { ComponentLoader, Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { Color } from '@hofy/theme';
import { AsyncButton, ErrorStatePlaceholder } from '@hofy/ui';

import { BYODOrderItemsScanSelect } from './BYODOrderItemsScanSelect';

interface ReceiveBYODOrderSlideoutProps {
    id: UUID;
    onClose(): void;
}

export const ReceiveBYODOrderSlideout: FC<ReceiveBYODOrderSlideoutProps> = ({ id, onClose }) => {
    const { data: byodOrder, isLoading, isError } = useBYODOrderQuery(id);
    if (isLoading) {
        return <ComponentLoader />;
    }
    if (isError || !byodOrder) {
        return <ErrorStatePlaceholder />;
    }

    return (
        <Slideout width={1200} onClose={onClose}>
            <SlideoutHeader
                title={`Receive items at the warehouse for #${byodOrder.publicId}`}
                color={Color.ContentPrimary}
            />
            <SlideoutContent>
                <BYODOrderItemsScanSelect byodOrderId={byodOrder.id} onItemToScanSelect={noop} />
            </SlideoutContent>

            <SlideoutFooter fullWidth justify='flex-end'>
                <AsyncButton onClick={onClose} label='Close' marginLeft={20} />
            </SlideoutFooter>
        </Slideout>
    );
};
