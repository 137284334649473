import React, { FC } from 'react';

import { nonOrganizationEntryTypes, nonRentalEntryTypes, rentalEntryTypes } from '@hofy/api-shared';
import { BackButton, Slideout, SlideoutContent, SlideoutFooter, SlideoutHeader } from '@hofy/common';
import { UUID } from '@hofy/global';
import { AsyncButton, SvgIcon } from '@hofy/ui';

import { useCreateInvoiceEntry } from '../../../../store/invoiceEntries/useCreateInvoiceEntry';
import { InvoiceEntryForm } from '../components/InvoiceEntryForm';

interface CreateInvoiceEntrySlideoutProps {
    organizationId?: UUID;
    basePath: string;
    contractId?: number;
    onClose(): void;
}

export const CreateInvoiceEntrySlideout: FC<CreateInvoiceEntrySlideoutProps> = ({
    onClose,
    basePath,
    organizationId,
    contractId,
}) => {
    const { form, isLoadingMutation, availableSubContracts } = useCreateInvoiceEntry(
        contractId ?? null,
        onClose,
    );

    const contractualAvailableTypes = contractId ? rentalEntryTypes : nonRentalEntryTypes;
    const availableEntryTypes = organizationId ? contractualAvailableTypes : nonOrganizationEntryTypes;

    return (
        <Slideout width={900} onClose={onClose}>
            <SlideoutHeader title='Add Invoice Entry' />
            <SlideoutContent borderBottom paddingBottom={30}>
                <InvoiceEntryForm
                    organizationId={organizationId}
                    formState={form}
                    entryTypes={availableEntryTypes}
                    availableSubContracts={availableSubContracts}
                />
            </SlideoutContent>
            <SlideoutFooter>
                <BackButton defaultNavigation={basePath} label='Close' leftIcon={SvgIcon.Cross} />
                <AsyncButton
                    isLoading={isLoadingMutation}
                    label='Add'
                    onClick={form.form.submit}
                    disableCheck
                />
            </SlideoutFooter>
        </Slideout>
    );
};
