import { AnyEnum, getEnumValues } from '@hofy/global';
import { queryStringArrayParser } from '@hofy/router';
import { FilterDefinitionMulti } from '@hofy/ui';

interface Options<T, E extends AnyEnum> {
    statusEnum: E;
    toLabel(value: T): string;
    allValues?: T[];
    showInActive?: boolean;
}

export const useStatusFilterDefinition = <T extends string, E extends AnyEnum>({
    statusEnum,
    toLabel,
    allValues = getEnumValues<T>(statusEnum),
    showInActive,
}: Options<T, E>): FilterDefinitionMulti<T> => {
    return {
        type: 'multi',
        name: 'Status',
        query: queryStringArrayParser<T>(),
        allValues: allValues as NonNullable<T>[],
        toLabel,
        showInActive,
    };
};
