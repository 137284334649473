import React, { FC } from 'react';

import { Permission } from '@hofy/api-shared';
import { useSession } from '@hofy/auth';
import { MoreMenu } from '@hofy/common';
import { SvgIcon } from '@hofy/ui';

interface BYODOrderActionMenuProps {
    onReceiveBYODOrder(): void;
}

export const BYODOrderActionMenu: FC<BYODOrderActionMenuProps> = ({ onReceiveBYODOrder }) => {
    const { hasPermission } = useSession();
    if (!hasPermission(Permission.AdminBYODOrdersReceive)) {
        return null;
    }

    return (
        <MoreMenu
            items={[
                {
                    label: 'Receive',
                    icon: SvgIcon.Box,
                    action: onReceiveBYODOrder,
                },
            ]}
        />
    );
};
