import React, { FC, useMemo } from 'react';

import { BYODOrderItemDto } from '@hofy/api-admin';
import { useTrProductCategory } from '@hofy/api-shared';
import { ProductImage } from '@hofy/product';
import { Color } from '@hofy/theme';
import { Box, Icon, InnerCard, Paragraph2, Paragraph3, SvgIcon } from '@hofy/ui';

interface BYODOrderItemBoxProps {
    item: BYODOrderItemDto;
    onItemToScanSelect(itemToScan: BYODOrderItemDto): void;
}

export const BYODOrderItemBox: FC<BYODOrderItemBoxProps> = ({ item, onItemToScanSelect }) => {
    const isLineAllowedToReceive = useMemo(() => {
        return item && item.receivedQuantity !== item.expectedQuantity;
    }, [item]);

    const trCategory = useTrProductCategory();

    return (
        <InnerCard pointer={isLineAllowedToReceive}>
            <Box
                fullWidth
                row
                justify='space-between'
                onClick={isLineAllowedToReceive ? () => onItemToScanSelect(item) : () => {}}
            >
                <Box row gap={12}>
                    <ProductImage size={58} image={item.variant.image} category={item.product.category} />
                    <Box>
                        <Paragraph3 bold color={Color.ContentPrimary}>
                            {item.product.name}
                        </Paragraph3>
                        <Paragraph3>{trCategory(item.product.category)}</Paragraph3>
                    </Box>
                </Box>
                <Box row gap={24}>
                    <Box>
                        <Paragraph3 paddingVertical={4}>Expected quantity</Paragraph3>
                        <Paragraph2 color={Color.ContentPrimary}>{item.expectedQuantity}</Paragraph2>
                    </Box>
                    <Box>
                        <Paragraph3 paddingVertical={4}>Received quantity</Paragraph3>
                        <Paragraph2 color={Color.ContentPrimary}>
                            {!isLineAllowedToReceive ? (
                                <Icon svg={SvgIcon.CheckCircle} color={Color.ContentPositive} />
                            ) : (
                                item.receivedQuantity || '--'
                            )}
                        </Paragraph2>
                    </Box>
                    <Icon svg={SvgIcon.ChevronRight} />
                </Box>
            </Box>
        </InnerCard>
    );
};
