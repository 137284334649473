import { keyBy } from 'lodash';
import React, { forwardRef, ReactElement, Ref, useMemo, useState } from 'react';

import { UUID } from '@hofy/global';
import { LabeledSelectCommonProps, LabeledSelectSearch } from '@hofy/ui';

import { useVariantRefsQuery } from '../../../store/products/useVariantRefsQuery';

export type LabeledVariantSelectProps = LabeledSelectCommonProps<UUID>;

export const LabeledVariantSelect = forwardRef(
    (
        { label, value, onChange, isRequired, errorMessage, onBlur, nullable }: LabeledVariantSelectProps,
        ref: Ref<HTMLDivElement>,
    ) => {
        const [search, setSearch] = useState('');

        const { data } = useVariantRefsQuery(search);
        const labelMap = useMemo(() => keyBy(data, v => v.id), [data]);

        return (
            <LabeledSelectSearch
                toText={variantId => labelMap[variantId]?.sku}
                options={data.map(v => v.id)}
                onSearchChange={setSearch}
                ref={ref}
                label={label}
                value={value}
                onChange={onChange}
                isRequired={isRequired}
                errorMessage={errorMessage}
                onBlur={onBlur}
                nullable={nullable}
            />
        );
    },
) as (props: LabeledVariantSelectProps & { ref?: Ref<HTMLDivElement> }) => ReactElement;
