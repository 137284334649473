import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import {
    emptyInvoiceEntryPayload,
    invoiceEntriesQueryKey,
    InvoiceEntryPayload,
    invoiceEntryService,
    useInvoiceEntry,
} from '@hofy/api-admin';
import { ContractType, periodRequiredTypes } from '@hofy/api-shared';
import { useToast } from '@hofy/ui';

import { useInvoiceEntryForm } from './useInvoiceEntryForm';

export const useUpdateInvoiceEntry = (invoiceEntryId: number, onSuccess?: () => void) => {
    const queryClient = useQueryClient();
    const { showToast } = useToast();
    const { invoiceEntry, isLoading, isError } = useInvoiceEntry(invoiceEntryId);

    const editMutation = useMutation({
        mutationFn: (p: InvoiceEntryPayload) => invoiceEntryService.updateInvoiceEntry(invoiceEntryId, p),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [invoiceEntriesQueryKey] });
            showToast({
                type: 'positive',
                message: 'Invoice entry modified',
            });
            onSuccess?.();
        },
    });

    const attachedContractType = () => {
        if (!invoiceEntry?.contract) {
            return null;
        }
        if (invoiceEntry.contract.managementContractId) {
            return ContractType.Management;
        }
        if (invoiceEntry.contract.rentalContractId) {
            return ContractType.Rental;
        }
        return ContractType.Purchase;
    };
    useEffect(() => {
        if (invoiceEntry) {
            const contractType = attachedContractType();
            const newState: InvoiceEntryPayload = {
                type: invoiceEntry.type,
                contractType: contractType,
                productCategory: null,
                invoiceTime: invoiceEntry.invoiceTime,
                invoicePeriodFrom: null,
                invoicePeriodTo: null,
                description: invoiceEntry.description,
                quantity: invoiceEntry.quantity,
                unitPrice: invoiceEntry.unitPrice,
                price: invoiceEntry.price,
                discount: invoiceEntry.discount,
                hofySubsidiaryId: invoiceEntry.hofySubsidiary.id,
                billingEntityId: invoiceEntry.billingEntity.id,
                contractId: invoiceEntry.contract?.id || null,
                subContractId:
                    invoiceEntry.contract?.purchaseContractId ??
                    invoiceEntry.contract?.rentalContractId ??
                    invoiceEntry.contract?.managementContractId ??
                    null,
            };
            if (periodRequiredTypes.includes(newState.type!)) {
                newState.invoicePeriodFrom = invoiceEntry.invoicePeriodFrom;
                newState.invoicePeriodTo = invoiceEntry.invoicePeriodTo;
            }
            form.form.setValues(newState);
        }
    }, [invoiceEntry]);

    const form = useInvoiceEntryForm(emptyInvoiceEntryPayload, editMutation.mutate);

    return {
        form,
        invoiceEntry,
        isLoadingMutation: editMutation.isPending,
        isLoading,
        isError,
    };
};
